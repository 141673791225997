<template>
  <v-card>
    <v-card-title>
      <v-row class="my-2 font-weight-medium">
        <v-col cols="1">
          <v-btn icon outlined color="primary" @click="closeDetails()">
            <v-icon> mdi-close </v-icon></v-btn
          >
        </v-col>
        <v-col cols="9">
          <span class="text-center font-weight-medium"
            >Detalhes da Mensagem</span
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Status</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.statusMessage != ''">{{
            message.statusMessage | filterMessageStatus
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Tipo de mensagem</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.typeMessage != ''">{{
            message.typeMessage | filterTypeMessage
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>N° Pedido Winthor</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.numberOrderWinthor != null">{{
            message.numberOrderWinthor
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>N° Pedido Web</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.numberOrderWeb != null">{{
            message.numberOrderWeb
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.nameIntegrator != ''">{{
            message.nameIntegrator
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Contato Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.phoneIntegrator != ''">{{
            message.phoneIntegrator | filterPhoneNumber
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
        <v-row justify="center" v-if="message.errorMessage != ''">
          <v-col class="border" cols="6" sm="4" md="4">
            <h4>Erro mensagem</h4>
          </v-col>
          <v-col class="border" cols="6" sm="6" md="6">
            {{ message.errorMessage }}
          </v-col>
        </v-row>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Data de pagamento</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.datePayment != ''">{{
            message.datePayment | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="border" cols="6" sm="4" md="4">
          <h4>Data de produção</h4>
        </v-col>
        <v-col class="border" cols="6" sm="6" md="6">
          <span v-if="message.dateProduction != ''"
            >{{ message.dateProduction | formatDate }}
          </span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="border" cols="6" sm="4" md="4">
          <h4>Data de despacho</h4>
        </v-col>
        <v-col class="border" cols="6" sm="6" md="6">
          <span v-if="message.dateDispatched != ''"
            >{{ message.dateDispatched | formatDate }}}</span
          >
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-4">
        <v-col class="border" cols="6" sm="4" md="4">
          <h4>Data de envio</h4>
        </v-col>
        <v-col class="border" cols="6" sm="6" md="6">
          <span v-if="message.dateSend != ''">{{
            message.dateSend | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DetailsMessagesReportDialog",
  data: () => ({}),
  props: {
    message: { required: true, type: Object },
  },
  methods: {
    closeDetails() {
      this.$emit("closeDetails");
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      const isValidDate = new Date(dateToBeFormatted).getTime() > 0;

      if (!isValidDate) {
        return dateToBeFormatted;
      }

      const date = new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });

      const hour = new Date(dateToBeFormatted);
      return date + " " + formatter.format(hour);
    },
  },
};
</script>
<style scoped>
.border {
  border-right: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  border-top: 1px solid #b5b5b5;
  border-left: 1px solid #b5b5b5;
}
</style>
