import { DashboardRepository } from "../../repositories/DashboardRepository";

export default {
  async requestMessagesPerDay({ commit }, body) {
    const response = await DashboardRepository.requestMessages(body);
    if (response.status >= 200 && response.status < 300) {
      commit("setMessagesPerDay", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Erro ao requisitar dados",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async requestMessagesPerWeek({ commit }, body) {
    const response = await DashboardRepository.requestMessages(body);
    if (response.status >= 200 && response.status < 300) {
      commit("setMessagesPerWeek", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Erro ao requisitar dados",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async requestMessagesPerMonth({ commit }, body) {
    const response = await DashboardRepository.requestMessages(body);
    if (response.status >= 200 && response.status < 300) {
      commit("setMessagesPerMonth", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Erro ao requisitar dados",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async requestMessagesPerYear({ commit }, body) {
    const response = await DashboardRepository.requestMessages(body);
    if (response.status >= 200 && response.status < 300) {
      commit("setMessagesPerYear", response.data.data);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Erro ao requisitar dados",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
