import router from "@/router";
import { HttpRestService } from "@/services/http/HttpRestService";

export default {
  login({ commit }, payload) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro! Tente novamente",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário logado com sucesso",
    };
    HttpRestService.post("/login", payload).then((response) => {
      if (response.status === 200) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        commit("setLogin");
        localStorage.setItem("token", response.data.data.access_token);
        router.push("/dashboard");
        // console.log(response.data.data.access_token);
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  logout({ commit }) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro no logout",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário deslogado com sucesso",
    };
    HttpRestService.post("/logout").then((response) => {
      if (response.status === 200) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        Object.keys(JSON.parse(JSON.stringify(localStorage))).forEach((l) =>
          localStorage.removeItem(l)
        );
        commit("setLogout");
        router.replace("/");
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  async user({ commit }) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na aplicação",
    };
    const response = await HttpRestService.get("/me");
    if (response.status === 200) {
      // const res = Object.keys(JSON.parse(JSON.stringify(response.data.data)));
      commit("setUserData", response.data.data.data_user);
      commit("setMeRole", response.data.data.me_role);
      commit("setMyPermissions", response.data.data.my_permissions);
      if (response.data.data.me_role.name === "Administrator") {
        commit("setAdministrator");
      }
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
};
