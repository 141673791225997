import Vue from "vue";
import VueRouter from "vue-router";
// import OrdersView from "../views/OrdersView";
import UsersView from "../views/UsersView";
import MessagesView from "../views/MessagesView";
// import ErrorsView from "../views/ErrorsView";
// import ScheduleView from "../views/ScheduleView";
// import DashboardView from "../views/DashboardView";
import LoginComponent from "../components/Login";
import ExternalMessagesView from "../views/ExternalMessagesView.vue";
import MessageReportView from "../views/MessageReportView.vue";
import store from "@/store/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Login",
      component: LoginComponent,
    },
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   component: DashboardView,
    //   meta: {
    //     requiresAuth: true,
    //     requiresPerm: false,
    //     permissions: "VIEW_DASHBOARD",
    //   },
    // },
    {
      path: "/mensagens",
      name: "Mensagens",
      component: MessagesView,
      meta: {
        requiresAuth: true,
        requiresPerm: false,
        permissions: "VIEW_LIST_OF_LOGS_MESSAGES",
      },
    },
    // {
    //   path: "/mensagens-com-erro",
    //   name: "Com Erro",
    //   component: ErrorsView,
    //   meta: {
    //     requiresAuth: true,
    //     requiresPerm: false,
    //     permissions: "VIEW_LIST_OF_MESSAGES_ERRORS",
    //   },
    // },
    // {
    //   path: "/pedidos",
    //   name: "Pedidos",
    //   component: OrdersView,
    //   // beforeEnter: beforeEnter,
    //   meta: {
    //     requiresAuth: true,
    //     requiresPerm: false,
    //     permissions: "VIEW_LIST_OF_LOGS_MESSAGES",
    //   },
    // },
    {
      path: "/usuarios",
      name: "Usuários",
      component: UsersView,
      // beforeEnter: beforeEnter,
      meta: {
        requiresAuth: true,
        requiresPerm: true,
        permissions: "VIEW_LIST_OF_USERS",
      },
    },
    // {
    //   path: "/agendamento",
    //   name: "Agendamento de Mensagens",
    //   component: ScheduleView,
    //   // beforeEnter: beforeEnter,
    //   meta: {
    //     requiresAuth: true,
    //     requiresPerm: false,
    //     permissions: "VIEW_LIST_OF_SCHEDULE_MESSAGES",
    //   },
    // },
    {
      path: "/mensagens-externas",
      name: "Mensagens Externas",
      component: ExternalMessagesView,
      // beforeEnter: beforeEnter,
      meta: {
        // requiresAuth: true,
        // requiresPerm: false,
        // permissions: "VIEW_LIST_OF_SCHEDULE_MESSAGES",
      },
    },
    {
      path: "/relatorio-mensagens",
      name: "Relatório de Mensagens",
      component: MessageReportView,
      // beforeEnter: beforeEnter,
      meta: {
        // requiresAuth: true,
        // requiresPerm: false,
        // permissions: "VIEW_LIST_OF_SCHEDULE_MESSAGES",
      },
    },
    { path: "*", redirect: { name: "Mensagens" } },
  ],
});

// function beforeEnter(to, from, next) {
//   if (store.getters["auth/getAdministrator"]) {
//     next();
//   } else {
//     next({
//       name: "Login",
//     });
//   }
// }

router.beforeEach((to, from, next) => {
  const myPermissions = store.state.auth.myPermissions;
  const permissionMapped = myPermissions.map((a) => a.slug);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/getLogin"]) {
      next({
        path: "/",
      });
    } else {
      if (to.matched.some((record) => record.meta.requiresPerm)) {
        if (permissionMapped.includes(to.meta.permissions)) {
          next();
        } else {
          next({
            path: "/mensagens",
          });
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path === "/" && store.getters["auth/getLogin"]) {
      next({
        path: "/mensagens",
      });
    } else {
      next();
    }
  }
});

export default router;
