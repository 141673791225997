import { HttpRestService } from "@/services/http/HttpRestService";
import { UsersRepository } from "../../repositories/UsersRepository";

export default {
  async errors({ commit, state }) {
    commit("setCheckedErrorMessages", []);
    commit("setIsLoading", true);
    const urlParams = state.urlParams;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/blip-messages/errors?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search != "") {
      url = url.concat(`&numberOrderWeb=${urlParams.search}`);
    }
    if (urlParams.initialDate != "") {
      url = url.concat(`&dateOrderInitial=${urlParams.initialDate}`);
    }
    if (urlParams.finalDate != "") {
      url = url.concat(`&dateOrderFinal=${urlParams.finalDate}`);
    }
    if (urlParams.status != "") {
      url = url.concat(`&orderStatus=${urlParams.status}`);
    }
    const response = await HttpRestService.get(url);
    commit("setIsLoading", false);
    if (response.status >= 200 && response.status < 300) {
      commit("setErrorMessages", response.data.data.list);
      commit("setErrorsPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  sendErrors({ commit }, checkedErrorMessages) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro no envio",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Mensagens enviadas com sucesso",
    };
    const body = {
      listErrorMessages: checkedErrorMessages,
    };
    HttpRestService.post("/blip-messages/errors/sent", body).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          commit("notifications/setSuccessNotification", successObj, {
            root: true,
          });
          location.reload();
          return;
        }
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
    );
  },

  async editPhone({ commit }, modelUpdatePhone) {
    const response = await UsersRepository.editPhone(modelUpdatePhone);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Contato editado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Algo deu errado ao editar o contato.",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setUrlParams({ commit }, urlParams) {
    commit("setUrlParams", urlParams);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setCheckedErrorMessages({ commit }, value) {
    commit("setCheckedErrorMessages", value);
  },
};
