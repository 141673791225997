import { HttpRestService } from "@/services/http/HttpRestService";
import env from "../../env";
export default class MessagesRepository {
  static getMessages(urlParams) {
    return HttpRestService.get(`/pedido/mensagem/listagem`, urlParams);
  }

  static downloadReportMessages(url, value) {
    return HttpRestService.get(url, value, {
      responseType: "blob",
    });
  }

  static async downloadReportMessagesXLS(url) {
    try {
      const requestUrl = `${env.baseUrl}${url}`;
      const response = await fetch(requestUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", "report.xls");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  }
}
