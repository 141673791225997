<template>
  <div>
    <div>
      <v-row dense class="my-3" justify="center" @keyup.enter="search">
        <v-col cols="12" sm="7" md="6">
          <v-text-field
            placeholder="Nome"
            dense
            solo
            flat
            outlined
            v-model="urlParams.search"
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="5" md="3" align="center">
          <default-button @click="search" :loading="loading"
            ><span>Filtrar</span></default-button
          >
        </v-col>
        <v-col cols="12" sm="5" md="3" justify="center">
          <default-button @click="openCreateUsersDialog"
            ><span>Criar Usuário</span></default-button
          >
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialogCreateUsers"
      scrollable
      max-width="720"
      transition="dialog-bottom-transition"
    >
      <create-users @closeCreateUsersDialog="closeCreateUsersDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateUsers from "./CreateUsers.vue";
import DefaultButton from "../defaultComponents/DefaultButton.vue";

export default {
  name: "UsersHeader",
  components: { CreateUsers, DefaultButton },
  data: () => ({
    dialogCreateUsers: false,
    menu1: false,
    menu2: false,
    loading: false,
    urlParams: {
      page: 1,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("users", ["getListingUsers", "getUsersPagination"]),
  },
  methods: {
    ...mapActions("users", ["users", "setUrlParams", "setIsLoading"]),

    async search() {
      this.loading = true;
      this.setIsLoading(true);
      const urlParams = {
        page: 1,
        search: this.urlParams.search ?? "",
      };
      await this.setUrlParams(urlParams);
      await this.users();
      this.setIsLoading(false);
      this.loading = false;
    },

    openCreateUsersDialog() {
      this.dialogCreateUsers = true;
    },

    closeCreateUsersDialog() {
      this.dialogCreateUsers = false;
    },
  },
};
</script>

<style scoped></style>
