<template>
  <v-progress-circular
    v-bind="$attrs"
    color="primary"
    indeterminate
    :size="size"
  ></v-progress-circular>
</template>

<script>
export default {
  name: "DefaultSpinner",
  props: {
    size: {
      type: String,
      default: "64",
    },
  },
};
</script>

<style scoped></style>
