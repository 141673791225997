<template>
  <v-card>
    <v-card-title>
      <v-row class="my-2 font-weight-medium">
        <v-col cols="12">
          <span class="text-center font-weight-medium">Dados da Mensagem</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Status do Envio</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.statusEnvio == null"> Não Encontrado</span>
          <span v-else-if="message.statusEnvio == 0">Não Enviado</span>
          <span v-else-if="message.statusEnvio == 1">Enviado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Id Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.integradorId != ''">{{
            message.integradorId
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Descrição do Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.integradorNome != ''">{{
            message.integradorNome
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>CNPJ do Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.integradorId != ''">{{
            message.integradorId
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Contato do Integrador</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.numeroTelefoneEnvio != ''">{{
            message.numeroTelefoneEnvio
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Falha da Mensagem</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.detalhesFalha != ''">{{
            message.detalhesFalha
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Data de pagamento</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.dataPagamento != ''">{{
            message.dataPagamento | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Data de despacho</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.dataDespacho != ''">{{
            message.dataDespacho | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Data real da entrega</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.dataEntrega != ''">{{
            message.dataEntrega | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" sm="4" md="4" class="border">
          <h4>Data de envio</h4>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="border">
          <span v-if="message.dataEnvioMensagem != ''">{{
            message.dataEnvioMensagem | formatDate
          }}</span>
          <span v-else>Não encontrado</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-col cols="4">
        <default-button @click="closeDataMessagesDialog">
          <span>Ok</span>
        </default-button>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
import DefaultButton from "../defaultComponents/DefaultButton.vue";

export default {
  name: "DataMessagesDialog",
  components: { DefaultButton },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeDataMessagesDialog() {
      this.$emit("closeDataMessageDialog");
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      const isValidDate = new Date(dateToBeFormatted).getTime() > 0;

      if (!isValidDate) {
        return dateToBeFormatted;
      }

      const date = new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      //   const formatter = new Intl.DateTimeFormat("pt-BR", {
      //     hour: "2-digit",
      //     minute: "2-digit",
      //   });

      //   const hour = new Date(dateToBeFormatted);
      return date;
    },
  },
};
</script>
<style scoped>
.border {
  border-right: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  border-top: 1px solid #b5b5b5;
  border-left: 1px solid #b5b5b5;
}
h4 {
  font-weight: bold;
  text-align: left;
}
</style>
