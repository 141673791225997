<template>
  <v-container>
    <messages-report-filter />
    <messages-report-list />
  </v-container>
</template>

<script>
import MessagesReportList from "@/components/messagesReport/MessagesReportList.vue";
import MessagesReportFilter from "@/components/messagesReport/MessagesReportFilter.vue";
export default {
  components: { MessagesReportList, MessagesReportFilter },
};
</script>

<style scoped></style>
