import { HttpRestService } from "@/services/http/HttpRestService";

export default {
  async users({ commit, state }) {
    const urlParams = state.urlParams;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/users?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search != "") {
      url = url.concat(`&userName=${urlParams.search}`);
    }
    const response = await HttpRestService.get(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingUsers", response.data.data.list);
      commit("setUsersStatus", response.data.data.list.isActive);
      commit("setUsersPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  newUser({ commit }, payload) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Não foi possível criar novo usuário. Tente novamente",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário criado com sucesso",
    };
    HttpRestService.post("/users", payload).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        location.reload();
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  deleteUser({ commit }, userUuid) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Não foi possível deletar o usuário. Tente novamente",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário deletado com sucesso",
    };
    const url = `/users/${userUuid}`;
    HttpRestService.delete(url).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        location.reload();
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  enableUser({ commit }, userUuid) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Não foi possível ativar o usuário. Tente novamente",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário ativado com sucesso",
    };
    const url = `/users/${userUuid}/enable`;
    HttpRestService.patch(url).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        location.reload();
        // commit("setUserEnabled");
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  disableUser({ commit }, userUuid) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Não foi possível desativar o usuário. Tente novamente",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Usuário desativado com sucesso",
    };
    const url = `/users/${userUuid}/disable`;
    HttpRestService.patch(url).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        location.reload();
        // commit("setUserDisabled");
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  setUrlParams({ commit }, urlParams) {
    commit("setUrlParams", urlParams);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
