export default {
  setListingMessagesReport(state, response) {
    state.listingMessagesReport = response;
  },

  setFilteredListingMessagesReport(state, response) {
    state.listingMessagesReport = response;
  },

  setMessagesReportPagination(state, response) {
    state.messagesReportPagination.total = response.total;
    state.messagesReportPagination.perPage = response.perPage;
    state.messagesReportPagination.currentPage = response.currentPage;
    state.messagesReportPagination.lastPage = response.lastPage;
    state.messagesReportPagination.nextPage = response.nextPage;
  },

  setUrlParams(state, bodyParams) {
    state.bodyParams.page = bodyParams.page;
    state.bodyParams.idIntegrator = bodyParams.idIntegrator;
    state.bodyParams.numberOrderWinthor = bodyParams.numberOrderWinthor;
    state.bodyParams.numberOrderWeb = bodyParams.numberOrderWeb;
    state.bodyParams.typeMessage = bodyParams.typeMessage;
    state.bodyParams.errorMessage = bodyParams.errorMessage;
    state.bodyParams.dataEnvioFinal = bodyParams.dataEnvioFinal;
    state.bodyParams.dataEnvioInicial = bodyParams.dataEnvioInicial;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setFilters(state, bodyFilters) {
    state.bodyFilters.idIntegrador = bodyFilters.idIntegrador;
    state.bodyFilters.numberOrderWeb = bodyFilters.numberOrderWeb;
    state.bodyFilters.numberOrderWinthor = bodyFilters.numberOrderWinthor;
    state.bodyFilters.dataEnvioFinal = bodyFilters.dataEnvioFinal;
    state.bodyFilters.dataEnvioInicial = bodyFilters.dataEnvioInicial;
    state.bodyFilters.typeMessage = bodyFilters.typeMessage;
    state.bodyFilters.errorMessage = bodyFilters.errorMessage;
  },
};
