<template>
  <v-card>
    <v-card-title>
      <span class="text-h5 font-weight-bold">Novo Registro - Usuário</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters class="justify-center mt-4 mb-2">
          <v-col cols="10" align="start">
            <div class="text-subtitle-2">Login</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center">
          <v-col cols="10">
            <v-text-field
              filled
              label="Login"
              single-line
              v-model="modelCreate.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center mb-2">
          <v-col cols="10" align="start">
            <div class="text-subtitle-2">Nome</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center">
          <v-col cols="10">
            <v-text-field
              filled
              label="Funcionário"
              single-line
              v-model="modelCreate.name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center mb-2">
          <v-col cols="10" align="start">
            <div class="text-subtitle-2">Senha</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center">
          <v-col cols="10">
            <v-text-field
              filled
              label="Senha"
              single-line
              v-model="modelCreate.password"
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row no-gutters class="justify-end">
          <v-col cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                rounded
                :color="hover ? 'secondary' : 'primary'"
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                @click="onGetCreateData"
                >Salvar</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    modelCreate: {
      email: "",
      name: "",
      password: "",
    },
  }),
  methods: {
    ...mapActions("users", ["newUser"]),
    onGetCreateData() {
      this.newUser(this.modelCreate);
      this.$emit("closeCreateUsersDialog");
    },
  },
};
</script>
<style scoped></style>
