<template>
  <v-container fluid>
    <the-header />
    <the-listing />
  </v-container>
</template>

<script>
import TheHeader from "../components/messages_new/TheHeader.vue";
import TheListing from "../components/messages_new/TheListing.vue";
export default {
  name: "MessagesView",
  components: {
    TheHeader,
    TheListing,
  },
};
</script>

<style scoped></style>
