export default {
  setMessagesPerDay(state, response) {
    state.messagesPerDay = response;
  },

  setMessagesPerWeek(state, response) {
    state.messagesPerWeek = response;
  },

  setMessagesPerMonth(state, response) {
    state.messagesPerMonth = response;
  },

  setMessagesPerYear(state, response) {
    state.messagesPerYear = response;
  },
};
