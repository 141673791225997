<template>
  <v-card flat>
    <v-app-bar flat color="base" absolute app>
      <v-toolbar-title
        class="flex text-start text-h5 text-md-h4 text--text font-weight-bold"
        >{{ name }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <span class="text--text text-body-1"> {{ getUserName }} </span>
      <v-avatar size="64">
        <v-icon color="navy">mdi-account</v-icon>
      </v-avatar>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    name: { type: String, required: true },
  },
  computed: {
    ...mapGetters("auth", ["getUserName"]),
  },
  methods: {
    ...mapActions("auth", ["user"]),
  },
  mounted() {
    this.user();
  },
};
</script>

<style>
.page-title {
  color: #002233;
}
</style>
