<template>
  <div>
    <default-table-head class="d-none d-md-flex">
      <v-row class="mx-auto my-auto mt-auto" align="center">
        <v-col cols="2" class="border-right">
          <span>Pedido Loja</span>
        </v-col>
        <v-col cols="2" class="border-right">
          <span>Pedido Winthor</span>
        </v-col>
        <v-col cols="2" class="border-right">
          <span>Cód. Integrador</span>
        </v-col>
        <v-col cols="2" class="border-right">
          <span>Status Envio</span>
        </v-col>
        <v-col cols="2" class="border-right">
          <span>Motivo Erro</span>
        </v-col>
        <v-col cols="2" class="py-0"
          ><v-btn
            fab
            x-small
            color="primary"
            title="Gerar Relatório"
            @click="messagesReportDialog = true"
          >
            <v-icon color="navy"> mdi-file-chart </v-icon>
          </v-btn>
          <v-dialog width="600" v-model="messagesReportDialog">
            <messages-report-dialog
              @close="close"
              :messagesReportDialog="messagesReportDialog"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </default-table-head>
    <template v-if="!getIsLoading">
      <default-table-body-row
        v-for="message in getListingMessagesReport"
        :key="message.messageUuid"
      >
        <v-col cols="12" sm="6" md="2"
          ><span class="d-block d-md-none font-weight-bold">Pedido Loja</span>
          <span class="text-wrap" :title="message.numberOrderWeb">
            {{ message.numberOrderWeb }}
          </span></v-col
        >
        <v-col cols="12" sm="6" md="2"
          ><span class="d-block d-md-none font-weight-bold"
            >Pedido Winthor</span
          >
          <span
            class="d-block text-wrap mb-n2"
            :title="message.numberOrderWinthor"
          >
            {{ message.numberOrderWinthor }}
          </span></v-col
        >
        <v-col cols="12" sm="6" md="2"
          ><span class="d-block d-md-none font-weight-bold"
            >Cód. Integrador</span
          >
          <span
            class="text-wrap secondary--text font-weight-bold"
            :title="message.idIntegrator"
          >
            {{ message.idIntegrator }}
          </span></v-col
        >

        <v-col cols="12" sm="6" md="2"
          ><span class="d-block d-md-none font-weight-bold">Status Envio</span>
          <span
            class="text-wrap"
            :title="
              message.statusMessage === ''
                ? '-'
                : message.statusMessage | filterMessageStatus
            "
          >
            {{
              message.statusMessage === ""
                ? "-"
                : message.statusMessage | filterMessageStatus
            }}
          </span></v-col
        >

        <v-col cols="12" sm="6" md="2"
          ><span class="d-block d-md-none font-weight-bold">Motivo Erro</span>
          <span
            class="text-wrap"
            :title="message.errorMessage === '' ? '-' : message.errorMessage"
          >
            {{ message.errorMessage === "" ? "-" : message.errorMessage }}
          </span></v-col
        >
        <v-col cols="12" sm="6" md="2">
          <default-button @click="openDetails(message)">
            <span>Detalhes</span>
          </default-button>
        </v-col>
      </default-table-body-row>
      <v-col>
        <default-pagination
          v-model="getMessagesReportPagination.currentPage"
          :length="getMessagesReportPagination.lastPage"
          @input="proxPage(getMessagesReportPagination.currentPage)"
          :disabled="getListingMessagesReport.length === 0"
        />
      </v-col>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <v-dialog width="600" v-model="detailsDialog">
      <details-messages-report-dialog
        :message="message"
        :key="reRender"
        @closeDetails="closeDetails"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPagination from "../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../defaultComponents/DefaultSpinner.vue";
import DefaultTableBodyRow from "../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../defaultComponents/DefaultTableHead.vue";
import DetailsMessagesReportDialog from "./DetailsMessagesReportDialog.vue";
import MessagesReportDialog from "@/components/messagesReport/MessagesReportDialog.vue";

import DefaultButton from "../defaultComponents/DefaultButton.vue";

export default {
  name: "MessagesReportListing",
  components: {
    DefaultPagination,
    DefaultSpinner,
    DefaultTableBodyRow,
    DefaultTableHead,
    DetailsMessagesReportDialog,
    DefaultButton,
    MessagesReportDialog,
  },
  data: () => ({
    detailsDialog: false,
    bodyParams: {
      page: 1,
      perPage: 10,
      idIntegrator: "",
      numberOrderWinthor: "",
      numberOrderWeb: "",
      typeMessage: "",
      errorMessage: [],
    },
    message: {},
    messagesReportDialog: false,
    reRender: 0,
  }),
  computed: {
    ...mapGetters("messagesReport", [
      "getListingMessagesReport",
      "getMessagesReportPagination",
      "getUrlParams",
      "getIsLoading",
    ]),
  },
  methods: {
    ...mapActions("messagesReport", [
      "messagesReport",
      "setUrlParams",
      "setIsLoading",
    ]),
    async proxPage(page) {
      let tempUrl = this.getUrlParams;
      this.bodyParams = tempUrl;
      this.bodyParams.page = page;
      await this.setUrlParams(this.bodyParams);
      await this.messagesReport();
    },

    async listMessagesReport() {
      await this.setUrlParams(this.bodyParams);
      await this.messagesReport();
    },
    openDetails(message) {
      this.reRender += 1;
      this.message = message;
      this.detailsDialog = true;
    },
    closeDetails() {
      this.detailsDialog = false;
    },
    close() {
      this.messagesReportDialog = false;
    },
  },
  created() {
    this.listMessagesReport();
  },
  filters: {
    formatStatus(status) {
      const statusTypes = {
        PAYMENT_PENDING: "Aguardando Confirmação do Pagamento",
        ORDER_DISPATCHED: "Pedido em Trânsito",
        CONFIRM_ADDRESS: "Confirmação de Endereço",
      };
      return statusTypes[status] ?? status;
    },
  },
};
</script>

<style scoped></style>
