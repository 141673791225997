<template>
  <v-form ref="form" class="mb-3">
    <v-row dense align="end" @keyup.enter="filter">
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          clearable
          hide-details
          outlined
          :label="orderLabel"
          style="padding-right: 0 !important"
          class="order pr-0"
          dense
          flat
          background-color="white"
          v-model="inputGroup"
          :title="inputGroup"
        >
          <template v-slot:append>
            <v-select
              @change="resetInputGroup"
              outlined
              :items="inputTypes"
              item-text="label"
              persistent-placeholder
              v-model="selectedInput"
              dense
              hide-details
              filled
              title="Mais Opções"
            ></v-select>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          dense
          outlined
          clearable
          hide-details
          label="Status do Pedido"
          background-color="white"
          :items="selectableOrderStatuses"
          v-model="orderStatus"
          item-text="label"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-select
          dense
          outlined
          clearable
          hide-details
          label="Tipo da Mensagem"
          color="primary"
          background-color="white"
          :items="selectableMessageTypes"
          v-model="messageType"
          item-text="label"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <default-date-picker
          :picker-date.sync="startDate"
          label="Data Inicial"
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <default-date-picker :picker-date.sync="endDate" label="Data Final" />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <default-button @click="filter">Filtrar</default-button>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../defaultComponents/DefaultDatePicker.vue";

export default {
  name: "TheHeader",
  components: {
    DefaultButton,
    DefaultDatePicker,
  },
  data() {
    return {
      inputTypes: [
        { label: "Pedido Loja", value: "storeOrder" },
        { label: "Pedido Winthor", value: "winthorOrder" },
        { label: "ID Integrador", value: "integratorId" },
      ],
      selectableOrderStatuses: [
        { label: "Aguardando Pagamento", value: "Aguardando_Pagamento" },
        { label: "Pagamento Reconhecido", value: "Pagamento_reconhecido" },
        { label: "Pedido em Trânsito", value: "Em_Transito" },
        { label: "Pedido Entregue", value: "Pedido_entregue" },
      ],
      selectableMessageTypes: [
        { label: "Pedido Realizado", value: "CONFIRMACAO_PEDIDO" },
        { label: "Pagamento Reconhecido", value: "CONFIRMACAO_PAGAMENTO" },
        { label: "Chegada à Transportadora", value: "PEDIDO_DESPACHADO" },
        { label: "Pedido em Rota", value: "PEDIDO_EM_ROTA" },
        { label: "Pedido Entregue", value: "PEDIDO_ENTREGUE" },
        { label: "Anexar NF", value: "transfer-pending" },
        { label: "Repasse Programado", value: "transfer-scheduled" },
        { label: "NF Recusada", value: "transfer-disapproved" },
        { label: "Garantia", value: "message-guarantee" },
        { label: "Avaria/Reposição", value: "message-faulty" },
      ],
      selectedInput: "storeOrder",
    };
  },
  methods: {
    ...mapActions("messages", [
      "messages",
      "setUrlParams",
      "setCurrentPageToFirstPage",
    ]),
    resetInputGroup() {
      this.$store.dispatch("messages/setUrlParams", {
        ...this.getUrlParams,
        numeroPedidoLoja: "",
        numeroPedidoWinthor: "",
        integradorId: "",
      });
    },
    filter() {
      this.setCurrentPageToFirstPage();
      this.messages();
    },
  },
  computed: {
    ...mapGetters("messages", ["getUrlParams"]),
    orderLabel() {
      const labels = {
        storeOrder: "Pedido Loja",
        winthorOrder: "Pedido Winthor",
        integratorId: "ID Integrador",
      };
      return labels[this.selectedInput] ?? this.selectedInput;
    },
    inputGroup: {
      get() {
        switch (this.selectedInput) {
          case "storeOrder":
            return this.getUrlParams.numeroPedidoLoja;
          case "winthorOrder":
            return this.getUrlParams.numeroPedidoWinthor;
          case "integratorId":
            return this.getUrlParams.integradorId;
          default:
            return "";
        }
      },
      set(newValue) {
        switch (this.selectedInput) {
          case "storeOrder":
            this.setUrlParams({
              ...this.getUrlParams,
              numeroPedidoLoja: newValue,
            });
            break;
          case "winthorOrder":
            this.setUrlParams({
              ...this.getUrlParams,
              numeroPedidoWinthor: newValue,
            });
            break;
          case "integratorId":
            this.setUrlParams({ ...this.getUrlParams, integradorId: newValue });
            break;
        }
      },
    },
    orderStatus: {
      get() {
        return this.getUrlParams.statusLoja;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, statusLoja: newValue });
      },
    },
    messageType: {
      get() {
        return this.getUrlParams.tipoMensagem;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, tipoMensagem: newValue });
      },
    },
    startDate: {
      get() {
        return this.getUrlParams.periodoInicial;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, periodoInicial: newValue });
      },
    },
    endDate: {
      get() {
        return this.getUrlParams.periodoFinal;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, periodoFinal: newValue });
      },
    },
  },
};
</script>

<style scoped>
::v-deep .order .v-input__slot {
  padding-right: 0 !important;
}

::v-deep .order .v-select__selections {
  display: none;
}

::v-deep .order .v-input__append-inner {
  align-self: center !important;
  margin-top: 0 !important;
}

::v-deep .order .v-input__icon--append {
  margin-right: 14px;
}
</style>
