export default {
  errorNotification: {
    errorStatus: false,
    errorMessage: "",
  },
  successNotification: {
    successStatus: false,
    successMessage: "",
  },
};
