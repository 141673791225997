<template>
  <default-table-body-row>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Pedido</span>
      <span class="d-block primary--text font-weight-bold mb-n2">{{
        message.numeroPedidoLoja
      }}</span>
      <span class="overline font-weight-regular"
        >WINTHOR: {{ message.numeroPedidoWinthor }}</span
      >
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <span class="d-block d-md-none font-weight-bold">Integrador</span>
      <span class="d-block primary--text font-weight-bold mb-n1"
        >ID {{ message.integradorId }}</span
      >
      <span class="">{{ message.integradorNome }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Status do Pedido</span>
      <span>{{ message.statusLoja }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Tipo da Mensagem</span>
      <span>{{ message.tipoMensagem | formatMessageType }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Status do Envio</span>
      <span class="green--text" v-if="message.statusEnvio == 1"
        ><v-icon color="green" small>mdi-send-variant-outline</v-icon>
        Enviado</span
      >
      <span v-else class="pink--text">
        <v-icon color="pink" small>mdi-close-octagon-outline</v-icon>
        Falha</span
      >
      <span class="d-block overline font-weight-regular mt-n2">{{
        message.dataEnvioMensagem | formatDate
      }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="1">
      <default-fab tooltip-text="Detalhes da Mensagem">
        <v-icon @click="openDataMessageDialog">mdi-text-box-search</v-icon>
      </default-fab>
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultTableBodyRow from "../defaultComponents/DefaultTableBodyRow.vue";
import DefaultFab from "../defaultComponents/DefaultFab.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultFab,
    DefaultTableBodyRow,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openDataMessageDialog() {
      this.$emit("openDataMessageDialog", this.message);
    },
  },
  filters: {
    formatMessageType(messageType) {
      const messageTypes = {
        CONFIRMACAO_PEDIDO: "Pedido Realizado",
        CONFIRMACAO_PAGAMENTO: "Pagamento Reconhecido",
        PEDIDO_DESPACHADO: "Chegada à Transportadora",
        PEDIDO_EM_ROTA: "Pedido em Rota",
        PEDIDO_ENTREGUE: "Pedido Entregue",
        "transfer-pending": "Anexar NF",
        "transfer-disapproved": "NF Recusada",
        "transfer-scheduled": "Repasse Programado",
        "message-guarantee": "Garantia",
        "message-faulty": "Avaria/Reposição",
      };
      return messageTypes[messageType] ?? messageType;
    },
    formatDate(date) {
      const [formatedDate] = date.match(/\d{4}-\d{2}-\d{2}/) || [];
      return formatedDate ? formatedDate.split("-").reverse().join("/") : "";
    },
  },
};
</script>

<style scoped></style>
