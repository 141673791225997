import { HttpRestService } from "@/services/http/HttpRestService";

export default {
  async externalMessages({ commit, state }) {
    commit("setCheckedExternalMessages", []);
    const urlParams = state.urlParams;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/messages-listing?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.numberOrder != "") {
      url = url.concat(`&numberOrder=${urlParams.numberOrder}`);
    }
    if (urlParams.noteType != "") {
      url = url.concat(`&noteType=${urlParams.noteType}`);
    }
    if (urlParams.status != "") {
      url = url.concat(`&status=${urlParams.status}`);
    }
    if (urlParams.initialDate != "") {
      url = url.concat(`&dateInitial=${urlParams.initialDate}`);
    }
    if (urlParams.finalDate != "") {
      url = url.concat(`&dateFinal=${urlParams.finalDate}`);
    }
    const response = await HttpRestService.get(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setExternalMessages", response.data.data.list);
      commit("setPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async editPhone({ commit }, body) {
    const response = await HttpRestService.put("/change-whatsapp-number", body);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Contato editado com sucesso!",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Algo deu errado ao editar o contato.",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async sendMessage({ commit }, body) {
    const response = await HttpRestService.post("/message/resend/transfer", {
      listErrorMessages: body,
    });
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Envio realizado com sucesso",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Houve um problema durante o envio",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setUrlParams({ commit }, urlParams) {
    commit("setUrlParams", urlParams);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setCheckedExternalMessages({ commit }, value) {
    commit("setCheckedExternalMessages", value);
  },
};
