<template>
  <v-container fluid>
    <external-messages-header />
    <external-messages-listing />
  </v-container>
</template>

<script>
import ExternalMessagesHeader from "@/components/external-messages/ExternalMessagesHeader.vue";
import ExternalMessagesListing from "@/components/external-messages/ExternalMessagesListing.vue";
export default {
  name: "ExternalMessagesView",
  components: {
    ExternalMessagesHeader,
    ExternalMessagesListing,
  },
};
</script>

<style scoped></style>
