<template>
  <v-hover v-slot="{ hover }">
    <v-row
      v-bind="$attrs"
      :class="`text--text ma-auto mt-2 ${hover ? 'hoverGlobal' : 'base_dark'}`"
      align-md="center"
    >
      <slot></slot>
    </v-row>
  </v-hover>
</template>

<script>
export default {
  name: "DefaultTableBodyRow",
};
</script>

<style scoped></style>
