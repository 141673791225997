<template>
  <v-container fluid>
    <users-header />
    <users-listing />
  </v-container>
</template>

<script>
import UsersHeader from "@/components/users/UsersHeader.vue";
import UsersListing from "@/components/users/UsersListing.vue";
export default {
  components: { UsersHeader, UsersListing },
};
</script>

<style scoped></style>
