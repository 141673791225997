<template>
  <default-fab v-on="$listeners" v-bind="$attrs" tooltip-text="Limpar Filtros"
    ><v-icon>mdi-filter-remove-outline</v-icon></default-fab
  >
</template>

<script>
import DefaultFab from "./DefaultFab.vue";
export default {
  name: "DefaultFilterCleaner",
  components: {
    DefaultFab,
  },
};
</script>

<style scoped></style>
