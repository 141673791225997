import MessagesRepository from "@/repositories/MessagesRepository";
import state from "./state";

export default {
  async messages({ state, commit }) {
    try {
      commit("setIsLoadingMessages", true);
      commit("setMessages", []);
      const response = await MessagesRepository.getMessages(state.urlParams);
      if (response.status === 200) {
        const messages = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setMessages", messages);
        commit("setPagination", pagination);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        {
          errorStatus: true,
          errorMessage: response.data.message,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingMessages", false);
    }
  },
  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },
  setCurrentPageToFirstPage({ commit, state }) {
    commit("setUrlParams", { ...state.urlParams, page: 1 });
  },
  resetUrlParams({ commit }) {
    const originalUrlParams = state.urlParams;
    commit("setUrlParams", originalUrlParams);
  },

  async downloadReportMessages({ commit }, value) {
    commit("setIsLoading", true);
    const url = `/pedido/mensagem/relatorio`;
    const queryParams = new URLSearchParams();
    Object.keys(value).forEach((key) => {
      if (value[key] !== "" && value[key] !== null) {
        queryParams.append(key, value[key]);
      }
    });
    const mountedUrl = `${url}?${queryParams.toString()}`;
    await MessagesRepository.downloadReportMessagesXLS(mountedUrl);
  },
};
