<template>
  <default-table-head>
    <v-col cols="2" class="border-right">Pedido</v-col>
    <v-col cols="3" class="border-right">Integrador</v-col>
    <v-col cols="2" class="border-right text-truncate" title="Status do Pedido">
      Status do Pedido
    </v-col>
    <v-col cols="2" class="border-right">Tipo da Mensagem</v-col>
    <v-col cols="2" class="text-truncate" title="Status do Envio">
      Status do Envio
    </v-col>
    <v-col cols="1" class="pa-0">
      <default-fab
        tooltip-text="Relatório de Mensagens"
        @click="openDialogReportMessages"
      >
        <v-icon>mdi-file-download</v-icon>
      </default-fab>
    </v-col>
  </default-table-head>
</template>

<script>
import DefaultFab from "../defaultComponents/DefaultFab.vue";
import DefaultTableHead from "../defaultComponents/DefaultTableHead.vue";

export default {
  name: "ListingHead",
  components: {
    DefaultFab,
    DefaultTableHead,
  },
  methods: {
    openDialogReportMessages() {
      this.$emit("openDialogReportMessages");
    },
  },
};
</script>

<style scoped></style>
