import { HttpRestService } from "@/services/http/HttpRestService";
import { HttpDownloadService } from "@/services/http/HttpDownloadService";

export default {
  async messagesReport({ commit, state }) {
    commit("setIsLoading", true);
    const body = state.bodyParams;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/orders/list-report`;
    const response = await HttpRestService.post(url, body);
    commit("setIsLoading", false);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingMessagesReport", response.data.data.list);
      commit("setMessagesReportPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async downloadMessagesReport({ commit, state }) {
    const body = state.bodyFilters;
    let url = `orders/report?page=${1}&perPage=${100000}`;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu ao baixar o relatório de mensagens.",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Download de relatório concluído com sucesso.",
    };
    const DownloadService = new HttpDownloadService();
    const response = await DownloadService.mountedPDF(
      url,
      body,
      "relatorio-mensagens",
      "xlsx"
    );
    if (response >= 200 && response < 300) {
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  setUrlParams({ commit }, urlParams) {
    commit("setUrlParams", urlParams);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  },
};
