<template>
  <div>
    <listing-head
      class="d-none d-md-flex"
      @openDialogReportMessages="openDialogReportMessages"
    />
    <template v-if="!getIsLoadingMessages">
      <template v-if="getMessages.length > 0">
        <listing-row
          v-for="(message, index) in getMessages"
          :key="'message' + index"
          :message="message"
          @openDataMessageDialog="openDataMessageDialog(message)"
        />
        <default-pagination
          v-model="currentPage"
          :length="getPagination.lastPage"
          @input="changePage"
        />
      </template>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <v-dialog width="600" v-model="dialogReportMessages">
      <report-messages-dialog :key="indexReport" />
    </v-dialog>
    <v-dialog width="600" v-model="dialogDataMessage">
      <data-message-dialog
        :key="indexMessage"
        :message="messageDetail"
        @closeDataMessageDialog="closeDataMessageDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPagination from "../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../defaultComponents/DefaultSpinner.vue";
import ListingHead from "./ListingHead.vue";
import ListingRow from "./ListingRow.vue";
import ReportMessagesDialog from "./ReportMessagesDialog.vue";
import DataMessageDialog from "./DataMessagesDialog.vue";

export default {
  name: "TheListing",
  data: () => ({
    dialogReportMessages: false,
    dialogDataMessage: false,
    indexMessage: 0,
    indexReport: 0,
    messageDetail: {},
  }),
  components: {
    DefaultPagination,
    DefaultSpinner,
    ListingHead,
    ListingRow,
    ReportMessagesDialog,
    DataMessageDialog,
  },
  created() {
    this.resetUrlParams();
    this.messages();
  },
  methods: {
    ...mapActions("messages", ["messages", "setUrlParams", "resetUrlParams"]),
    changePage() {
      this.messages();
    },
    openDialogReportMessages() {
      this.indexReport++;
      this.dialogReportMessages = true;
    },
    closeDialogReportMessages() {
      this.dialogReportMessages = false;
    },
    openDataMessageDialog(message) {
      this.dialogDataMessage = true;
      this.indexMessage++;
      this.messageDetail = message;
    },
    closeDataMessageDialog() {
      this.dialogDataMessage = false;
    },
  },
  computed: {
    ...mapGetters("messages", [
      "getMessages",
      "getUrlParams",
      "getPagination",
      "getIsLoadingMessages",
    ]),
    currentPage: {
      get() {
        return this.getUrlParams.page;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, page: newValue });
      },
    },
  },
};
</script>

<style scoped></style>
