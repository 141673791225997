<template>
  <div>
    <default-table-head>
      <v-col
        cols="2"
        offset="5"
        offset-md="0"
        md="1"
        class="d-flex justify-center"
      >
        <default-checkbox
          :indeterminate="
            getCheckedExternalMessages.length > 0 && !areAllCheckboxesChecked
          "
          :disabled="getExternalMessages.length === 0"
          :inputValue="areAllCheckboxesChecked"
          :title="areAllCheckboxesChecked ? 'Desmarcar Todos' : 'Marcar Todos'"
          @click="checkAll"
        />
      </v-col>
      <v-col class="d-block d-md-none" align="end">
        <span
          v-if="checkedExternalMessages.length > 0"
          class="font-italic font-weight-light"
          ><span class="font-weight-bold"
            >{{ checkedExternalMessages.length }}
          </span>
          <span v-if="checkedExternalMessages.length === 1"
            >contato selecionado</span
          ><span v-else>contatos selecionados</span></span
        >
      </v-col>
      <v-col cols="3" class="d-none d-md-block border-right">
        <span>Cliente</span>
      </v-col>
      <v-col
        cols="2"
        class="d-none d-md-block border-right text-truncate"
        title="Status do Envio"
      >
        <span>Status do Envio</span>
      </v-col>
      <v-col cols="2" class="d-none d-md-block border-right">
        <span>Descrição</span>
      </v-col>
      <v-col cols="2" class="d-none d-md-block border-right">
        <span>Pedido</span>
      </v-col>
      <v-col
        cols="2"
        class="d-none d-md-block border-right text-truncate"
        title="Tipo da Mensagem"
      >
        <span>Tipo da Mensagem</span>
      </v-col>
    </default-table-head>
    <template v-if="!getIsLoading">
      <default-table-body-row
        v-for="externalMessage in sortedExternalMessages"
        :key="externalMessage.uuid"
      >
        <v-col cols="1" class="d-md-flex justify-center">
          <default-checkbox
            v-model="checkedExternalMessages"
            :inputValue="externalMessage.uuid"
          />
        </v-col>
        <v-col cols="10" sm="4" md="3"
          ><span class="d-md-none font-weight-bold">Cliente</span>
          <div class="text-wrap" :title="externalMessage.clientName">
            {{ externalMessage.clientName }}
          </div></v-col
        >
        <v-col
          align="center"
          cols="12"
          sm="6"
          offset-sm="1"
          offset-md="0"
          md="2"
          ><span class="d-block d-md-none font-weight-bold"
            >Status do Envio</span
          >
          <span
            class="text-wrap secondary--text font-weight-bold"
            :title="externalMessage.status | formatStatus"
          >
            {{ externalMessage.status | formatStatus }}
          </span>
          <default-fab
            :small="false"
            x-small
            v-if="externalMessage.status === 'error'"
            tooltip-text="Editar Contato"
            @click="openDialog(externalMessage)"
          >
            <v-icon>mdi-pencil</v-icon>
          </default-fab>
          <span
            v-if="externalMessage.createdAt"
            class="d-block text-overline font-weight-light mt-n1"
            >{{ externalMessage.createdAt | formatDate }}</span
          >
        </v-col>
        <v-col cols="12" sm="6" md="2"
          ><span class="d-md-none font-weight-bold">Descrição</span>
          <div class="text-wrap" :title="externalMessage.errorDescription">
            {{ externalMessage.errorDescription }}
          </div></v-col
        >
        <v-col cols="12" sm="6" md="2"
          ><span class="d-md-none font-weight-bold">Pedido</span>
          <div class="text-wrap" :title="externalMessage.numberOrder">
            {{ externalMessage.numberOrder }}
          </div></v-col
        >
        <v-col cols="12" sm="6" md="2"
          ><span class="d-md-none font-weight-bold">Tipo da Mensagem</span>
          <div
            class="text-wrap"
            :title="externalMessage.noteType | formatNoteType"
          >
            {{ externalMessage.noteType | formatNoteType }}
          </div></v-col
        >
      </default-table-body-row>
      <v-col>
        <default-pagination
          v-model="getPagination.currentPage"
          :length="getPagination.lastPage"
          :disabled="getExternalMessages.length === 0"
          @input="proxPage(getPagination.currentPage)"
        />
      </v-col>
    </template>
    <v-col v-else>
      <default-spinner />
    </v-col>
    <v-dialog
      v-model="dialogUpdatePhone"
      scrollable
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <edit-phone
        :key="reRender"
        :externalMessage="externalMessage"
        @closePhoneDialog="dialogUpdatePhone = false"
        @reloadListing="listExternalMessages"
      />
    </v-dialog>
    <default-fab
      :disabled="
        getCheckedExternalMessages.length === 0 ||
        getExternalMessages.length === 0
      "
      tooltip-text="Enviar Mensagem"
      fixed
      right
      bottom
      large
      @click="sendCheckedMessages"
    >
      <v-icon>mdi-send</v-icon>
    </default-fab>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultCheckbox from "../defaultComponents/DefaultCheckbox.vue";
import DefaultFab from "../defaultComponents/DefaultFab.vue";
import DefaultPagination from "../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../defaultComponents/DefaultSpinner.vue";
import DefaultTableBodyRow from "../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../defaultComponents/DefaultTableHead.vue";
import EditPhone from "./EditPhone.vue";
export default {
  name: "ExternalMessagesListing",
  components: {
    DefaultCheckbox,
    DefaultFab,
    DefaultPagination,
    DefaultSpinner,
    DefaultTableBodyRow,
    DefaultTableHead,
    EditPhone,
  },
  data: () => ({
    reRender: 0,
    externalMessage: {},
    dialogUpdatePhone: false,
    urlParams: {
      page: 1,
      numberOrder: "",
      initialDate: "",
      finalDate: "",
      noteType: "",
      status: "",
    },
  }),
  computed: {
    ...mapGetters("externalMessages", [
      "getCheckedExternalMessages",
      "getExternalMessages",
      "getPagination",
      "getUrlParams",
      "getIsLoading",
    ]),
    checkedExternalMessages: {
      get() {
        return this.getCheckedExternalMessages;
      },
      set(uuid) {
        this.setCheckedExternalMessages(uuid);
      },
    },
    sortedExternalMessages() {
      return [...this.getExternalMessages].sort((a) =>
        a.status === "error" ? -1 : 1
      );
    },
    externalMessagesUuid() {
      return this.getExternalMessages.map(
        (externalMessage) => externalMessage.uuid
      );
    },
    areAllCheckboxesChecked() {
      return (
        this.getCheckedExternalMessages.length ===
          this.externalMessagesUuid.length &&
        this.getCheckedExternalMessages.length > 0
      );
    },
  },
  methods: {
    ...mapActions("externalMessages", [
      "externalMessages",
      "sendMessage",
      "setUrlParams",
      "setIsLoading",
      "setCheckedExternalMessages",
    ]),
    sendCheckedMessages() {
      const checkedExternalMessages = [];
      for (let checkedExternalMessage of this.getCheckedExternalMessages) {
        checkedExternalMessages.push(checkedExternalMessage);
      }
      this.sendMessage(checkedExternalMessages);
    },
    openDialog(externalMessage) {
      this.externalMessage = externalMessage;
      this.dialogUpdatePhone = true;
      this.reRender += 1;
    },
    checkAll() {
      if (
        this.getCheckedExternalMessages.length ===
        this.externalMessagesUuid.length
      ) {
        this.setCheckedExternalMessages([]);
      } else {
        this.setCheckedExternalMessages(this.externalMessagesUuid);
      }
    },
    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.externalMessages();
      this.setIsLoading(false);
    },
    async listExternalMessages() {
      this.setIsLoading(true);
      await this.setUrlParams(this.urlParams);
      await this.externalMessages();
      this.setIsLoading(false);
    },
  },
  created() {
    this.listExternalMessages();
  },
  filters: {
    formatNoteType(noteType) {
      const noteTypes = {
        "message-guarantee": "Garantia",
        "message-faulty": "Mercadoria de Reposição",
        "transfer-scheduled": "Repasse Agendado",
        "transfer-pending": "Repasse Pendente",
        "transfer-disapproved": "Repasse Reprovado",
        schedule_chat: "",
      };
      return noteTypes[noteType] ?? noteType;
    },
    formatStatus(status) {
      const statuses = {
        sent: "Envio Realizado",
        error: "Erro no Envio",
      };
      return statuses[status] ?? status;
    },
    formatDate(date) {
      const [formatedDate] = date.match(/\d{4}-\d{2}-\d{2}/) || [];
      return formatedDate ? formatedDate.split("-").reverse().join("/") : "";
    },
  },
};
</script>

<style scoped></style>
