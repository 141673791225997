import { HttpRestService } from "@/services/http/HttpRestService";

export default {
  async schedules({ commit }) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/config-cron`;
    const response = await HttpRestService.get(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingSchedules", response.data.data);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  editSchedule({ commit }, modelUpdateSchedule) {
    let errorObj = {
      errorStatus: true,
      errorMessage:
        "Não foi possível editar agendamento. Tente novamente ou contate o suporte",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Agendamento editado com sucesso",
    };
    HttpRestService.put("/config-cron", modelUpdateSchedule).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          commit("notifications/setSuccessNotification", successObj, {
            root: true,
          });
          location.reload();
          return;
        }
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
    );
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
