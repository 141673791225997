export default {
  getErrorMessages(state) {
    return state.errorMessages;
  },

  getCheckedErrorMessages(state) {
    return state.checkedErrorMessages;
  },

  getErrorsPagination(state) {
    return state.errorsPagination;
  },

  getUrlParams(state) {
    return state.urlParams;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};
