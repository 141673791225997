export default {
  getCheckedExternalMessages(state) {
    return state.checkedExternalMessages;
  },

  getExternalMessages(state) {
    return state.externalMessages;
  },

  getPagination(state) {
    return state.pagination;
  },

  getUrlParams(state) {
    return state.urlParams;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};
