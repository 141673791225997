<template>
  <div>
    <v-row dense class="justify-center mt-3" @keyup.enter="search">
      <v-col cols="6" xs="2" sm="2" md="1" lg="1" xl="2">
        <v-select
          outlined
          v-model="selectTypeFilter"
          :items="selectTypeItemsFilter"
          item-text="title"
          item-value="value"
          return-object
          placeholder="Tipo"
          dense
          solo
          flat
        ></v-select>
      </v-col>
      <v-col cols="6" xs="2" sm="4" md="2" lg="2" xl="2">
        <v-text-field
          placeholder="Filtro Selecionado"
          dense
          outlined
          solo
          flat
          clearable
          v-model="bodyParams[selectTypeFilter.value]"
          :label="selectTypeFilter.title"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="2">
        <default-date-picker
          label="Data Inicial"
          :picker-date.sync="bodyParams.dataEnvioInicial"
        />
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="2">
        <default-date-picker
          label="Data Final"
          :picker-date.sync="bodyParams.dataEnvioFinal"
        />
      </v-col>
      <v-col cols="6" xs="3" sm="5" md="2" lg="2" xl="2">
        <v-select
          outlined
          v-model="selectType"
          :items="selectTypeItems"
          item-text="title"
          item-value="value"
          :title="avoidNull(selectType)"
          return-object
          placeholder="Mensagem"
          dense
          solo
          clearable
          flat
        ></v-select>
      </v-col>
      <v-col cols="6" xs="2" sm="5" md="2" lg="2" xl="2">
        <v-select
          outlined
          v-model="selectErrorMessage"
          :items="selectErrorMessageItems"
          item-text="title"
          item-value="value"
          :title="avoidNull(selectErrorMessage)"
          placeholder="Motivo Erro"
          dense
          solo
          clearable
          flat
          multiple
        ></v-select>
      </v-col>
      <v-col cols="12" xs="3" sm="2" md="1" lg="1" xl="2">
        <default-button :loading="buttonLoading" @click="search">
          <span>Filtrar</span>
        </default-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultButton from "../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../defaultComponents/DefaultDatePicker.vue";

export default {
  name: "MessagesReportHeader",
  components: {
    DefaultButton,
    DefaultDatePicker,
  },
  data: () => ({
    buttonLoading: false,
    selectErrorMessage: [],
    selectType: { title: "", value: "" },
    selectTypeFilter: { title: "", value: "" },
    selectErrorMessageItems: [
      {
        title: "Erro na requisição para Blip",
        value: "Erro na requisição para Blip.",
      },
      {
        title: "Erro no número de envio da mensagem",
        value: "Erro no número de envio da mensagem!",
      },
      {
        title: "Erro de caractere especial.",
        value: "Erro de caractere especial.",
      },
      {
        title: "Erro de número inválido.",
        value: "Erro de número inválido.",
      },
    ],
    selectedFilterLabel: "",
    selectTypeItemsFilter: [
      { title: "Cód. Integrador", value: "idIntegrator" },
      { title: "Ped. Winthor", value: "numberOrderWinthor" },
      { title: "Ped. Loja", value: "numberOrderWeb" },
    ],
    selectTypeItems: [
      {
        title: "Endereço Confirmado",
        value: "MESSAGE_CONFIRM_ADDRESS",
      },
      { title: "Pagamento Aprovado", value: "MESSAGE_PAYMENT_APPROVED" },
      { title: "Despacho", value: "MESSAGE_ORDER_DISPATCHED" },
    ],
    bodyParams: {
      page: 1,
      perPage: 10,
      idIntegrator: "",
      numberOrderWinthor: "",
      numberOrderWeb: "",
      typeMessage: "",
      errorMessage: [],
      dataEnvioInicial: "",
      dataEnvioFinal: "",
    },
  }),
  mounted() {
    this.clearFilter();
  },
  watch: {
    selectTypeFilter: {
      handler: "handleSelectTypeFilterChange",
      deep: true,
    },
  },
  computed: {
    ...mapGetters("messages", [
      "getListingMessagesReport",
      "getMessagesReportPagination",
    ]),
  },
  methods: {
    ...mapActions("messagesReport", [
      "messagesReport",
      "setUrlParams",
      "setIsLoading",
    ]),

    async search() {
      this.buttonLoading = true;
      const bodyParams = {
        page: 1,
        idIntegrator: this.bodyParams.idIntegrator ?? "",
        numberOrderWinthor: this.bodyParams.numberOrderWinthor ?? "",
        numberOrderWeb: this.bodyParams.numberOrderWeb ?? "",
        typeMessage: this.selectType?.value ?? "",
        errorMessage: this.selectErrorMessage ?? [],
        dataEnvioInicial: this.bodyParams.dataEnvioInicial ?? "",
        dataEnvioFinal: this.bodyParams.dataEnvioFinal ?? "",
      };
      await this.setUrlParams(bodyParams);
      await this.messagesReport();
      this.buttonLoading = false;
    },
    avoidNull(select) {
      return select?.title ?? "";
    },
    handleSelectTypeFilterChange() {
      this.bodyParams[this.selectTypeFilter.value] = this.selectTypeFilter
        ? ""
        : null;
    },
    clearFilter() {
      (this.bodyParams.dataEnvioInicial = ""),
        (this.bodyParams.dataEnvioFinal = "");
    },
  },
};
</script>

<style scoped></style>
