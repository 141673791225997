import { HttpRestService } from "@/services/http/HttpRestService";

export default {
  async orders({ commit, state }) {
    const urlParams = state.urlParams;
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro na listagem",
    };
    let url = `/orders?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.search != "") {
      url = url.concat(`&numberOrderWeb=${urlParams.search}`);
    }
    if (urlParams.initialDate != "") {
      url = url.concat(`&dateOrderInitial=${urlParams.initialDate}`);
    }
    if (urlParams.finalDate != "") {
      url = url.concat(`&dateOrderFinal=${urlParams.finalDate}`);
    }
    if (urlParams.status != "") {
      url = url.concat(`&orderStatus=${urlParams.status}`);
    }
    const response = await HttpRestService.get(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingOrders", response.data.data.list);
      commit("setOrdersPagination", response.data.data.pagination);
      return;
    }
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  sendOrders({ commit }, objSent) {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Ocorreu um erro no envio",
    };
    let successObj = {
      successStatus: true,
      successMessage: "Mensagens enviadas com sucesso",
    };
    console.log("objSent", objSent);
    const body = {
      listOrdersSentMessage: objSent,
    };
    HttpRestService.post("/orders/sent-messages", body).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        location.reload();
        return;
      }
      commit("notifications/setErrorNotification", errorObj, { root: true });
    });
  },

  setUrlParams({ commit }, urlParams) {
    commit("setUrlParams", urlParams);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
