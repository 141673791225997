<template>
  <v-checkbox
    v-on="$listeners"
    v-bind="$attrs"
    dense
    hide-details
    style="margin: 0; padding: 0"
    :value="inputValue"
    v-model="model"
  />
</template>

<script>
export default {
  name: "DefaultCheckbox",
  props: {
    inputValue: {},
    value: {},
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
::v-deep .mdi-checkbox-blank-outline,
::v-deep .mdi-minus-box,
::v-deep .mdi-checkbox-marked {
  color: #f9820b;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
