var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold",class:_vm.isTransportadora
        ? hover
          ? 'primary--text'
          : 'base--text'
        : hover
        ? 'base--text'
        : 'navy--text',staticStyle:{"text-transform":"none"},attrs:{"color":_vm.isTransportadora ? 'primaryTransportadora' : 'primary',"block":"","rounded":""}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }