export default {
  getMessages(state) {
    return state.messages;
  },
  getIsLoadingMessages(state) {
    return state.isLoadingMessages;
  },
  getPagination(state) {
    return state.pagination;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};
