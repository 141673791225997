<template>
  <v-card width="256" flat>
    <v-navigation-drawer
      permanent
      :mini-variant="mini"
      floating
      app
      color="base_dark"
      id="sidebar"
    >
      <v-list>
        <v-list-item>
          <v-img
            src="../assets/sou_energy_logo.png"
            class="mt-8 mb-5 justify-center"
            contain
            height="48"
          />
        </v-list-item>
      </v-list>

      <v-list dense nav>
        <v-list-item-group :value="selectedMenuItem" color="primary" mandatory>
          <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            @click="redirect(item.url)"
            link
            v-show="
              myPermissionsMapped.find((i) => i === item.permissions) ||
              !item.needPermission
            "
          >
            <v-list-item-icon class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-medium text-body-1 text-left align-self-start"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout" class="mt-5">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium text-body-1 text-left"
              >Sair</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import router from "@/router";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [
        // {
        //   title: "Dashboard",
        //   icon: "mdi-view-dashboard-outline",
        //   url: "/dashboard",
        //   admin: false,
        //   needPermission: false,
        //   permissions: "VIEW_DASHBOARD",
        // },
        // {
        //   title: "Pedidos",
        //   icon: "mdi-file-document-multiple-outline",
        //   url: "/pedidos",
        //   admin: false,
        //   needPermission: false,
        //   permissions: "VIEW_LIST_OF_LOGS_MESSAGES",
        // },
        {
          title: "Mensagens",
          icon: "mdi-email-outline",
          url: "/mensagens",
          admin: false,
          needPermission: false,
          permissions: "VIEW_LIST_OF_LOGS_MESSAGES",
        },
        // {
        //   title: "Mensagens Externas",
        //   icon: "mdi-email-arrow-left",
        //   url: "/mensagens-externas",
        //   admin: false,
        //   needPermission: false, // TODO: mudar pra true
        //   // permissions: "VIEW_LIST_OF_SCHEDULE_MESSAGES",
        // },
        // {
        //   title: "Com Erro",
        //   icon: "mdi-alert-circle-outline",
        //   url: "/mensagens-com-erro",
        //   admin: false,
        //   needPermission: false,
        //   permissions: "VIEW_LIST_OF_MESSAGES_ERRORS",
        // },
        {
          title: "Usuários",
          icon: "mdi-account-multiple-outline",
          url: "/usuarios",
          admin: true,
          needPermission: true,
          permissions: "VIEW_LIST_OF_USERS",
        },
        // {
        //   title: "Agendamento",
        //   icon: "mdi-calendar-outline",
        //   url: "/agendamento",
        //   admin: false,
        //   needPermission: false,
        //   permissions: "VIEW_LIST_OF_SCHEDULE_MESSAGES",
        // },
        // {
        //   title: "Relatório de Mensagens",
        //   icon: "mdi-chart-box-outline",
        //   url: "/relatorio-mensagens",
        //   admin: false,
        //   needPermission: false,
        //   permissions: "VIEW_LIST_OF_MESSAGE_REPORT",
        // },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    redirect(url) {
      const atual = router.currentRouter;
      if (atual != url) {
        router.replace(url);
      }
    },
    onLoggedOut() {
      this.logout();
    },
  },
  computed: {
    ...mapGetters("auth", [
      "getAdministrator",
      "getPermissions",
      "getRoleName",
    ]),
    selectedMenuItem() {
      return this.items.findIndex((item) =>
        RegExp(`^${item.url}/`).test(this.$route.path + "/")
      );
    },
    myPermissionsMapped() {
      return this.getPermissions.map((a) => a.slug);
    },
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    menu() {
      if (this.getRoleName === "Administrator") {
        return this.items;
      }

      return this.items.filter((item) => {
        return !item.admin;
      });
    },
  },
  created() {
    let atual = router.currentRouter;
    router.push(atual);
  },
};
</script>

<style scoped>
#side-bar {
  position: absolute;
  margin: 0;
}
</style>
