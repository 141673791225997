<template>
  <v-row justify="end" class="overflow-visible teste">
    <v-col cols="auto">
      <v-alert
        dismissible
        transition="scale-transition"
        type="success"
        v-model="successNotification.successStatus"
      >
        {{ successNotification.successMessage }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    successNotification: {
      successStatus: false,
      successMessage: "",
    },
  }),
  computed: {
    ...mapGetters("notifications", ["getSuccessNotification"]),
  },
  methods: {
    ...mapActions("notifications", ["dismissSuccessNotification"]),
    hideAlert() {
      window.setInterval(() => {
        this.successNotification.successStatus = false;
      }, 5000);
    },
  },
  mounted() {
    this.successNotification = this.getSuccessNotification;
    if (this.successNotification.successStatus) {
      this.hideAlert();
      this.dismissSuccessNotification();
    }
  },
};
</script>

<style scoped>
.teste {
  position: absolute;
  right: 2%;
  top: 1%;
  z-index: 2000;
}
</style>
