<template>
  <v-row justify="end" class="overflow-visible teste">
    <v-col cols="auto">
      <v-alert
        dismissible
        transition="scale-transition"
        type="error"
        v-model="errorNotification.errorStatus"
      >
        {{ errorNotification.errorMessage }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    errorNotification: {
      errorStatus: false,
      errorMessage: "",
    },
  }),
  computed: {
    ...mapGetters("notifications", ["getErrorNotification"]),
  },
  methods: {
    ...mapActions("notifications", ["dismissErrorNotification"]),
    hideAlert() {
      window.setInterval(() => {
        this.errorNotification.errorStatus = false;
      }, 5000);
    },
  },
  mounted() {
    this.errorNotification = this.getErrorNotification;
    if (this.errorNotification.errorStatus) {
      this.hideAlert();
      this.dismissErrorNotification();
    }
  },
};
</script>

<style scoped>
.teste {
  position: absolute;
  right: 2%;
  top: 1%;
  z-index: 2000;
}
</style>
