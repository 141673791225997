import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";

Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.prototype.console = console;

Vue.filter("filterStatus", function (value) {
  switch (value) {
    case "CONFIRM_ADDRESS":
      return (value = "Confirmação de Endereço");
    case "PAYMENT_PENDING":
      return (value = "Aguardando Confirmação de Pagamento");
    case "ORDER_PRODUCTION":
      return (value = "Produção");
    case "ORDER_DISPATCHED":
      return (value = "Pedido em trânsito");
    case "PAYMENT_APPROVED":
      return (value = "Pagamento Reconhecido");
  }
});

Vue.filter("filterMessageStatus", function (value) {
  switch (value) {
    case "PENDING":
      return (value = "Pendente");
    case "SENDING":
      return (value = "Enviando");
    case "SENT":
      return (value = "Enviado");
    case "FAILED":
      return (value = "Erro no Envio");
    case "Ainda não enviado":
      return value;
  }
});

Vue.filter("filterTypeMessage", function (value) {
  switch (value) {
    case "MESSAGE_CONFIRM_ADDRESS":
      return (value = "Endereço Confirmado");
    case "MESSAGE_PAYMENT_APPROVED":
      return (value = "Pagamento Aprovado");
    case "MESSAGE_ORDER_DISPATCHED":
      return (value = "Despacho");
    case "MESSAGE_ORDER_PRODUCTION":
      return (value = "Produção");
  }
});

Vue.filter("filterMinuteType", function (value) {
  switch (value) {
    case "EXACT":
      return (value = "Exatamente o minuto");
    case "EVERY":
      return (value = "Intervalo");
    case "ALL":
      return (value = "A cada minuto");
  }
});

Vue.filter("filterHourType", function (value) {
  switch (value) {
    case "EXACT":
      return (value = "Exatamente a hora");
    case "EVERY":
      return (value = "Intervalo");
    case "ALL":
      return (value = "Toda hora");
  }
});

Vue.filter("filterMessageIdType", function (value) {
  switch (value) {
    case 1:
      // MESSAGE_PAYMENT_APPROVED = 1;
      return (value = "Pagamento Aprovado");
    case 2:
      // MESSAGE_CONFIRM_ADDRESS = 2;
      return (value = "Endereço Confirmado");
    case 3:
      // MESSAGE_ORDER_PRODUCTION = 3;
      return (value = "Produção");
    case 4:
      // MESSAGE_ORDER_DISPATCHED = 4;
      return (value = "Despacho");
  }
});

Vue.filter("filterPhoneNumber", function (value) {
  const clearPhoneNumber = value.replace(/\D/g, "");
  const format = "(00) 000000000";
  let indicePhone = 0;
  let FormatedPhoneNumber = "";

  for (let i = 0; i < format.length; i++) {
    if (format[i] === "0") {
      FormatedPhoneNumber += clearPhoneNumber[indicePhone] || "0";
      indicePhone++;
    } else {
      FormatedPhoneNumber += format[i];
    }
  }

  return FormatedPhoneNumber;
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
