import { render, staticRenderFns } from "./DefaultTableHead.vue?vue&type=template&id=9f468ea6&scoped=true&"
import script from "./DefaultTableHead.vue?vue&type=script&lang=js&"
export * from "./DefaultTableHead.vue?vue&type=script&lang=js&"
import style0 from "./DefaultTableHead.vue?vue&type=style&index=0&id=9f468ea6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f468ea6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
