import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#F9820B",
        secondary: "#E5620B",
        background: "#eeeeee",
        base: "#f2f4f5",
        base_dark: "#dadfe0",
        navy: "#0f2733",
        text: "#002233",
        white_bg: "#FFFFFF",
        hoverGlobal: "#cfd2d3",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
