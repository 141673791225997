export default {
  isLoading: false,
  listingUsers: [
    {
      userUuid: "",
      dateCreateUser: "",
      userName: "",
      userEmail: "",
      isActive: true,
    },
  ],
  filteredListingUsers: [],
  usersPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  isActive: true,
  urlParams: {
    page: 1,
    search: "",
  },
};
