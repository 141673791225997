<template>
  <div>
    <div>
      <default-table-head class="d-none d-md-flex">
        <v-col cols="4" class="border-right">
          <span>Nome</span>
        </v-col>
        <v-col cols="4" class="border-right">
          <span>Login</span>
        </v-col>
        <v-col cols="2" class="border-right">
          <span>Criação</span>
        </v-col>
        <v-col cols="2">
          <span>Status</span>
        </v-col>
      </default-table-head>
      <template v-if="!getIsLoading">
        <default-table-body-row
          v-for="user in getListingUsers"
          :key="user.userUuid"
        >
          <v-col cols="12" sm="6" md="4" class="text-wrap"
            ><span class="d-md-none font-weight-bold">Nome</span>
            <span
              :title="user.userName"
              class="d-block secondary--text font-weight-bold"
            >
              {{ user.userName }}
            </span></v-col
          >
          <v-col cols="12" sm="6" md="4" class="text-wrap text-truncate"
            ><span class="d-md-none font-weight-bold">Login</span>
            <span class="d-block" :title="user.userEmail">{{
              user.userEmail
            }}</span></v-col
          >
          <v-col cols="12" sm="6" md="2" class="text-wrap"
            ><span class="d-md-none font-weight-bold">Criação</span>
            <span class="d-block text-wrap mb-n2">
              {{ user.dateCreateUser | formatDate }}
            </span>
            <span class="text-wrap text-overline font-weight-light">
              {{ user.dateCreateUser | formatHour }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="1"
            ><span class="d-block d-md-none font-weight-bold">Status</span
            ><v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  large
                  :color="user.isActive ? 'green' : 'red'"
                  v-bind="attrs"
                  v-on="on"
                  >{{
                    user.isActive ? "mdi-account-check" : "mdi-account-off"
                  }}</v-icon
                >
              </template>
              <span>{{
                user.isActive ? "Usuário Ativo" : "Usuário Inativo"
              }}</span>
            </v-tooltip></v-col
          >
          <v-col cols="12" md="1" class="justify-center">
            <default-fab
              tooltip-text="Editar Usuário"
              @click="openEditUsersDialog(user.userUuid, user.isActive)"
              ><v-icon>mdi-pencil</v-icon></default-fab
            >
          </v-col>
        </default-table-body-row>
        <v-row class="justify-center mb-3">
          <default-pagination
            v-model="getUsersPagination.currentPage"
            :length="getUsersPagination.lastPage"
            @input="proxPage(getUsersPagination.currentPage)"
            :disabled="getListingUsers.length === 0"
          />
        </v-row>
      </template>
      <v-col v-else>
        <default-spinner />
      </v-col>
    </div>
    <v-dialog
      v-model="dialogEditUsers"
      scrollable
      max-width="720"
      transition="dialog-bottom-transition"
    >
      <edit-users
        :key="reRender"
        :userUuid="userUuid"
        :isActive="isActive"
        @closeEditUsersDialog="closeEditUsersDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultFab from "../defaultComponents/DefaultFab.vue";
import DefaultPagination from "../defaultComponents/DefaultPagination.vue";
import DefaultSpinner from "../defaultComponents/DefaultSpinner.vue";
import DefaultTableBodyRow from "../defaultComponents/DefaultTableBodyRow.vue";
import DefaultTableHead from "../defaultComponents/DefaultTableHead.vue";
import EditUsers from "./EditUsers.vue";

export default {
  name: "UsersListing",
  components: {
    DefaultFab,
    DefaultPagination,
    DefaultSpinner,
    DefaultTableBodyRow,
    DefaultTableHead,
    EditUsers,
  },
  data: () => ({
    dialogEditUsers: false,
    reRender: 0,
    userUuid: "",
    isActive: false,
    dialog: {},
    urlParams: {
      page: 1,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("users", [
      "getListingUsers",
      "getUsersPagination",
      "getUrlParams",
      "getIsLoading",
    ]),
  },
  methods: {
    ...mapActions("users", ["users", "setUrlParams", "setIsLoading"]),
    async proxPage(page) {
      this.setIsLoading(true);
      let tempUrl = this.getUrlParams;
      this.urlParams = tempUrl;
      this.urlParams.page = page;
      await this.setUrlParams(this.urlParams);
      await this.users();
      this.setIsLoading(false);
    },
    openEditUsersDialog(userUuid, isActive) {
      this.userUuid = userUuid;
      this.isActive = isActive;
      this.dialogEditUsers = true;
      this.reRender += 1;
    },

    closeEditUsersDialog() {
      this.dialogEditUsers = false;
    },

    async listUsers() {
      this.setIsLoading(true);
      await this.setUrlParams(this.urlParams);
      await this.users();
      this.setIsLoading(false);
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
  },
  mounted() {
    this.listUsers();
  },
};
</script>

<style scoped></style>
