<template>
  <v-row
    v-bind="$attrs"
    align="center"
    :class="`font-weight-bold ma-auto ${
      isTransportadora ? 'transportadora' : 'default'
    }`"
  >
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: "DefaultTableHead",
  props: {
    isTransportadora: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    defineBorderRightColor() {
      for (let element of this.$el.querySelectorAll(".border-right")) {
        element.style.borderRight = `1px solid ${
          this.isTransportadora ? "#0f2733" : "#4D6068"
        }`;
      }
    },
  },
  mounted() {
    this.defineBorderRightColor();
  },
};
</script>

<style scoped>
.default {
  background-color: #0f2733;
  color: white;
}

.transportadora {
  background-color: #c1c6c7;
  color: #0f2733;
}
</style>
