<template>
  <v-container class="mt-12">
    <v-row no-gutters class="justify-center">
      <v-col cols="6" align="center">
        <v-img
          src="../assets/sou_notification_logo.png"
          max-width="320px"
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-center mt-12 mb-2">
      <v-col cols="6" align="start">
        <div class="text-subtitle-1">Login</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-center">
      <v-col cols="6">
        <v-text-field
          label="Login"
          placeholder="Login"
          solo
          flat
          v-model="modelLogin.email"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-center mb-2">
      <v-col cols="6" align="start">
        <div class="text-subtitle-1">Senha</div>
      </v-col>
    </v-row>

    <v-row no-gutters class="justify-center">
      <v-col cols="6">
        <v-text-field
          label="Senha"
          placeholder="Senha"
          type="password"
          solo
          flat
          v-model="modelLogin.password"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters class="justify-center mt-6 mb-6">
      <v-col cols="4">
        <v-hover v-slot="{ hover }">
          <v-btn
            rounded
            :color="hover ? 'secondary' : 'primary'"
            x-large
            block
            class="navy--text"
            style="text-transform: none !important; font-weight: bolder"
            max-width="220px"
            @click="onGetLoginData(modelLogin.email, modelLogin.password)"
            >Login</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>

    <v-row no-gutters class="justify-center">
      <v-col cols="6" align="center">
        <v-img src="../assets/sou_energy_logo.png" max-width="120px"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginComponent",
  components: {},
  data: () => ({
    modelLogin: {
      email: "",
      password: "",
    },
  }),
  methods: {
    ...mapActions("auth", ["login"]),
    onGetLoginData() {
      this.login(this.modelLogin);
    },
  },
};
</script>
