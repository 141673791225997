export default {
  setMessages(state, payload) {
    state.messages = payload;
  },
  setIsLoadingMessages(state, payload) {
    state.isLoadingMessages = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
};
