<template>
  <v-card class="overflow-hidden">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text">Editar Contato</span>
    </v-card-title>
    <div v-if="!isLoadingModal">
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row justify="center">
              <v-col cols="11">
                <v-text-field
                  v-model="contact"
                  v-mask="'(##) #####-####'"
                  label="Contato"
                  outlined
                  :rules="[rules.counter]"
                  dense
                  autofocus
                  required
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <default-button @click="phoneUpdate">
                  <span>Salvar</span>
                </default-button>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { unmaskPhoneNumber } from "../../utils";
import DefaultButton from "../defaultComponents/DefaultButton.vue";
export default {
  name: "EditPhone",
  components: {
    DefaultButton,
  },
  props: {
    externalMessage: { type: Object, required: true },
  },
  data: () => ({
    isLoadingModal: false,
    isLoadingBtn: false,
    contact: "",
    rules: {
      counter: (maskedPhoneNumber) =>
        unmaskPhoneNumber(maskedPhoneNumber).length === 11 ||
        "O contato deve ter 11 dígitos",
    },
  }),
  methods: {
    ...mapActions("externalMessages", ["setIsLoading", "editPhone"]),
    async phoneUpdate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoadingBtn = true;
      const bodyUpdatePhone = {
        uuid: [this.externalMessage.uuid],
        phoneWhatsapp: unmaskPhoneNumber(this.contact),
      };
      this.$emit("closePhoneDialog");
      const response = await this.editPhone(bodyUpdatePhone);
      if (response.status >= 200 && response.status < 300) {
        setTimeout(() => {
          this.$emit("reloadListing");
        }, 1500);
        this.isLoadingBtn = false;
      }
    },
  },
  created() {
    const whatsappNumber = this.externalMessage.whatsappNumber;
    this.contact =
      whatsappNumber.length === 13
        ? whatsappNumber.substring(2)
        : whatsappNumber;
  },
};
</script>

<style scoped></style>
