import env from "../../../env";

export class HttpDownloadService {
  async mountedPDF(url, body, fileName, typeFile) {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${env.baseUrl}/${url}`, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body),
      redirect: "follow",
    });

    const status = response.status;

    if (status >= 200 && status < 300) {
      const requestBlob = await response.blob();
      const newBlob = new Blob([requestBlob], {
        type: `application/${typeFile}`,
      });

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${fileName}.${typeFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);

      return status;
    } else {
      return status;
    }
  }
}
