export default {
  messages: [],
  isLoadingMessages: false,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    perPage: 10,
    numeroPedidoLoja: "",
    numeroPedidoWinthor: "",
    integradorId: "",
    statusLoja: "",
    tipoMensagem: "",
    periodoInicial: "",
    periodoFinal: "",
  },
};
