import { render, staticRenderFns } from "./DefaultFab.vue?vue&type=template&id=7b9e5b14&scoped=true&"
import script from "./DefaultFab.vue?vue&type=script&lang=js&"
export * from "./DefaultFab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b9e5b14",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VHover,VTooltip})
