export default {
  getListingMessagesReport(state) {
    return state.listingMessagesReport;
  },

  getMessagesReportPagination(state) {
    return state.messagesReportPagination;
  },

  getUrlParams(state) {
    return state.bodyParams;
  },

  getIsLoading(state) {
    return state.isLoading;
  },

  getFilters(state) {
    return state.bodyFilters;
  },
};
