import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import dashboard from "./dashboard";
import errors from "./errors";
import orders from "./orders";
import users from "./users";
import messages from "./messages_new";
import externalMessages from "./external-messages";
import notifications from "./notifications";
import schedule from "./schedule";
import messagesReport from "./messagesReport";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const excludedModules = ["messages"];
    const filteredState = Object.keys(state).reduce((accumulator, module) => {
      if (!excludedModules.includes(module)) {
        accumulator[module] = state[module];
      }
      return accumulator;
    }, {});
    return filteredState;
  },
});

export default new Vuex.Store({
  modules: {
    namespaced: true,
    auth,
    dashboard,
    errors,
    orders,
    users,
    messages,
    externalMessages,
    schedule,
    notifications,
    messagesReport,
  },
  plugins: [vuexLocal.plugin],
});
