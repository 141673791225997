export default {
  getListingOrders(state) {
    return state.listingOrders;
  },

  getOrdersPagination(state) {
    return state.ordersPagination;
  },

  getUrlParams(state) {
    return state.urlParams;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};
