import { render, staticRenderFns } from "./DefaultPagination.vue?vue&type=template&id=38c1e01e&scoped=true&"
import script from "./DefaultPagination.vue?vue&type=script&lang=js&"
export * from "./DefaultPagination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c1e01e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VPagination})
