<template>
  <v-card>
    <v-card-title>
      <span class="text-h5 font-weight-bold">Editar Registro - Usuário</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters class="justify-center">
          <v-col cols="4" class="mt-5">
            <div class="text-body-1">Ativar / Desativar Usuário</div>
          </v-col>
          <v-col cols="2">
            <v-switch
              :input-value="this.value"
              @change="toggleStatus()"
              color="orange darken-3"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row no-gutters class="justify-space-between mb-3">
          <v-col cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                rounded
                :color="hover ? 'secondary' : 'base'"
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                @click="getUserDeleted(userUuid)"
                >Deletar</v-btn
              >
            </v-hover>
          </v-col>
          <v-col cols="3">
            <v-hover v-slot="{ hover }">
              <v-btn
                rounded
                :color="hover ? 'secondary' : 'primary'"
                block
                class="navy--text"
                style="text-transform: none !important; font-weight: bolder"
                max-width="220px"
                @click="updateUser(userUuid)"
                >Salvar</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    value: false,
  }),
  props: {
    userUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("users", ["getUsersIsActive"]),
    // isActiveModel: {
    //   get() {
    //     return this.isActive;
    //   },
    //   set(value) {
    //     this.value = value;
    //   },
    // },
  },
  mounted() {
    this.value = this.isActive;
  },
  methods: {
    ...mapActions("users", ["enableUser", "disableUser", "deleteUser"]),
    updateUser() {
      if (this.value === false) {
        this.disableUser(this.userUuid);
        this.$emit("closeEditUsersDialog");
      } else {
        this.enableUser(this.userUuid);
        this.$emit("closeEditUsersDialog");
      }
    },
    getUserDeleted() {
      this.deleteUser(this.userUuid);
      this.$emit("closeEditUsersDialog");
    },
    toggleStatus() {
      console.log("value", this.value);
      this.value = !this.value;
    },
  },
};
</script>
<style scoped></style>
