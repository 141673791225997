export default {
  isLoading: false,
  listingMessagesReport: [],
  messagesReportPagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  bodyParams: {
    page: 1,
    perPage: 10,
    idIntegrator: "",
    numberOrderWinthor: "",
    numberOrderWeb: "",
    typeMessage: "",
    errorMessage: [],
    dataEnvioInicial: "",
    dataEnvioFinal: "",
  },
  bodyFilters: {
    idIntegrador: 0,
    numberOrderWinthor: 0,
    numberOrderWxeb: 0,
    typeMessage: "",
    errorMessage: [],
    dataEnvioInicial: "",
    dataEnvioFinal: "",
  },
};
