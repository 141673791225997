export default {
  setListingOrders(state, response) {
    state.listingOrders = response;
  },

  setFilteredListingOrders(state, response) {
    state.listingOrders = response;
  },

  setOrdersPagination(state, response) {
    state.ordersPagination.total = response.total;
    state.ordersPagination.perPage = response.perPage;
    state.ordersPagination.currentPage = response.currentPage;
    state.ordersPagination.lastPage = response.lastPage;
    state.ordersPagination.nextPage = response.nextPage;
  },

  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.initialDate = urlParams.initialDate;
    state.urlParams.finalDate = urlParams.finalDate;
    state.urlParams.status = urlParams.status;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
};
