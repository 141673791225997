export default {
  setErrorMessages(state, response) {
    state.errorMessages = response;
  },

  setCheckedErrorMessages(state, response) {
    state.checkedErrorMessages = response;
  },

  setErrorsPagination(state, response) {
    state.errorsPagination.total = response.total;
    state.errorsPagination.perPage = response.perPage;
    state.errorsPagination.currentPage = response.currentPage;
    state.errorsPagination.lastPage = response.lastPage;
    state.errorsPagination.nextPage = response.nextPage;
  },

  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
    state.urlParams.initialDate = urlParams.initialDate;
    state.urlParams.finalDate = urlParams.finalDate;
    state.urlParams.status = urlParams.status;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
};
