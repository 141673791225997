<template>
  <v-app id="app">
    <div class="overflow-visible float-end teste">
      <error-alert />
      <success-alert />
    </div>
    <div v-if="!getLogin">
      <v-main>
        <login />
      </v-main>
    </div>
    <div v-else>
      <nav-bar v-if="!['Login'].includes($route.name)" :name="$route.name" />
      <side-bar v-if="!['Login'].includes($route.name)" />
      <div>
        <v-main>
          <router-view />
        </v-main>
      </div>
    </div>
  </v-app>
</template>

<script>
import Login from "./components/Login.vue";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import ErrorAlert from "./components/ErrorAlert.vue";
import SuccessAlert from "./components/SuccessAlert.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    SideBar,
    Login,
    ErrorAlert,
    SuccessAlert,
  },
  computed: {
    ...mapGetters("auth", ["getLogin"]),
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #eeeeee;
  text-transform: none !important;
}
</style>
