export default {
  getListingUsers(state) {
    return state.listingUsers;
  },

  getUsersPagination(state) {
    return state.usersPagination;
  },

  getUsersIsActive(state) {
    return state.isActive;
  },

  getUrlParams(state) {
    return state.urlParams;
  },

  getIsLoading(state) {
    return state.isLoading;
  },
};
