<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row class="my-2 font-weight-medium">
          <v-col cols="1">
            <v-btn icon outlined color="primary" @click="close()">
              <v-icon> mdi-close </v-icon></v-btn
            >
          </v-col>
          <v-col cols="9">
            <span class="text-center">Relatório de Mensagens</span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col class="mb-0 pb-0">
              <v-text-field
                v-model="filters.idIntegrador"
                outlined
                dense
                label="ID Integrador"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="my-0 py-0">
              <v-text-field
                v-model="filters.numberOrderWinthor"
                outlined
                dense
                label="N° Pedido Winthor"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <v-text-field
                v-model="filters.numberOrderWeb"
                outlined
                dense
                label="N° Pedido Web"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="my-0 py-0">
              <v-select
                v-model="filters.typeMessage"
                outlined
                item-text="item"
                item-value="value"
                :items="typeMessageItems"
                label="Tipo de Mensagem"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <v-select
                v-model="filters.errorMessage"
                outlined
                :items="errorMessageItems"
                label="Motivo Erro"
                clearable
                multiple
                chips
              ></v-select>
            </v-col>
          </v-row>
          <div
            class="font-weight-medium text-body-1 text-left align-self-start mb-6"
          >
            Data de envio:
          </div>
          <v-row>
            <v-col cols="6" class="my-0 py-0">
              <default-date-picker
                :picker-date.sync="filters.dataEnvioInicial"
                label="Data Início"
              ></default-date-picker>
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <default-date-picker
                :picker-date.sync="filters.dataEnvioFinal"
                label="Data Fim"
              ></default-date-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-center">
        <v-col cols="4">
          <default-button
            :loading="buttonDownloadReport"
            @click="downloadReport"
          >
            <span>Baixar relatório</span>
          </default-button>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DefaultButton from "../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../defaultComponents/DefaultDatePicker.vue";
export default {
  name: "MessagesReportDialog",
  components: { DefaultButton, DefaultDatePicker },
  data: () => ({
    buttonDownloadReport: false,
    filters: {
      idIntegrador: "",
      numberOrderWinthor: "",
      numberOrderWeb: "",
      typeMessage: "",
      errorMessage: [],
      dataEnvioInicial: "",
      dataEnvioFinal: "",
    },
    typeMessageItems: [
      { item: "Pagamento Aprovado", value: "MESSAGE_PAYMENT_APPROVED" },
      { item: "Endereço Confirmado", value: "MESSAGE_CONFIRM_ADDRESS" },
      { item: "Despacho", value: "MESSAGE_ORDER_DISPATCHED" },
    ],
    errorMessageItems: [
      "Erro na requisição para Blip.",
      "Erro no número de envio da mensagem!",
      "Erro de caractere especial.",
      "Erro de número inválido.",
    ],
  }),
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        this.checkDate(newFilters);
      },
    },
  },
  methods: {
    ...mapActions("messagesReport", [
      "downloadMessagesReport",
      "setFilters",
      "setIsLoading",
    ]),
    ...mapActions("notifications", ["setErrorNotification"]),

    checkDate(filters) {
      const { dataEnvioInicial, dataEnvioFinal } = filters;

      if (dataEnvioInicial && dataEnvioFinal) {
        const inicio = new Date(dataEnvioInicial);
        const fim = new Date(dataEnvioFinal);

        const msDifference = fim - inicio;
        const yearDifference = msDifference / (1000 * 60 * 60 * 24 * 365);

        if (yearDifference > 1) {
          let errorObj = {
            errorStatus: true,
            errorMessage: "O período não pode ser maior que 1 ano!",
          };
          this.filters.dataEnvioInicial = "";
          this.filters.dataEnvioFinal = "";
          this.setErrorNotification(errorObj);
        }
      }
    },

    close() {
      this.$emit("close");
      this.filters.idIntegrador = "";
      this.filters.numberOrderWeb = "";
      this.filters.numberOrderWinthor = "";
      this.filters.errorMessage = "";
      this.filters.typeMessage = "";
      this.filters.dataEnvioFinal = "";
      this.filters.dataEnvioInicial = "";
    },
    async downloadReport() {
      this.buttonDownloadReport = true;
      const filters = {
        idIntegrador: this.filters.idIntegrador ?? "",
        numberOrderWinthor: this.filters.numberOrderWinthor ?? "",
        numberOrderWeb: this.filters.numberOrderWeb ?? "",
        errorMessage: this.filters?.errorMessage ?? [],
        typeMessage: this.filters?.typeMessage ?? "",
        dataEnvioInicial: this.filters?.dataEnvioInicial ?? "",
        dataEnvioFinal: this.filters?.dataEnvioFinal ?? "",
      };
      await this.setFilters(filters);
      await this.downloadMessagesReport();
      this.buttonDownloadReport = false;
    },
  },
};
</script>
