<template>
  <div>
    <v-row
      align="start"
      class="mt-3"
      dense
      justify="center"
      @keyup.enter="search"
    >
      <v-col cols="12" sm="4" lg="2">
        <v-text-field
          placeholder="Pedido"
          :title="urlParams.numberOrder"
          dense
          outlined
          solo
          flat
          clearable
          v-model="urlParams.numberOrder"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="4" lg="2">
        <default-date-picker
          :picker-date.sync="urlParams.initialDate"
          label="Data Inicial"
        />
      </v-col>
      <v-col cols="6" sm="4" lg="2">
        <default-date-picker
          :picker-date.sync="urlParams.finalDate"
          label="Data Final"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          :title="avoidNull(selectNoteType)"
          v-model="selectNoteType"
          :items="selectNoteTypes"
          item-text="title"
          item-value="value"
          return-object
          dense
          outlined
          placeholder="Tipo da Mensagem"
          solo
          flat
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          :title="avoidNull(selectStatus)"
          v-model="selectStatus"
          :items="selectStatuses"
          item-text="title"
          item-value="value"
          return-object
          dense
          outlined
          placeholder="Status do Envio"
          flat
          solo
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-n5 mb-3">
      <v-col sm="6" md="3">
        <default-button :loading="buttonLoading" @click="search">
          <span>Filtrar</span>
        </default-button>
      </v-col>
      <default-filter-cleaner @click="cleanFilters" />
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultButton from "../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../defaultComponents/DefaultDatePicker.vue";
import DefaultFilterCleaner from "../defaultComponents/DefaultFilterCleaner.vue";
export default {
  name: "ExternalMessagesHeader",
  components: {
    DefaultButton,
    DefaultDatePicker,
    DefaultFilterCleaner,
  },
  data: () => ({
    buttonLoading: false,
    selectNoteType: { title: "", value: "" },
    selectNoteTypes: [
      { title: "Garantia", value: "message-guarantee" },
      { title: "Mercadoria de Reposição", value: "message-faulty" },
      { title: "Repasse Agendado", value: "transfer-scheduled" },
      { title: "Repasse Pendente", value: "transfer-pending" },
      { title: "Repasse Reprovado", value: "transfer-disapproved" },
    ],
    selectStatus: { title: "", value: "" },
    selectStatuses: [
      { title: "Envio Realizado", value: "sent" },
      { title: "Erro no Envio", value: "error" },
    ],
    urlParams: {
      page: 1,
      perPage: 10,
      numberOrder: "",
      initialDate: "",
      finalDate: "",
      noteType: "",
      status: "",
    },
  }),
  computed: {
    ...mapGetters("externalMessages", [
      "getExternalMessages",
      "getPagination",
      "getIsLoading",
    ]),
  },
  methods: {
    ...mapActions("externalMessages", [
      "externalMessages",
      "setUrlParams",
      "setIsLoading",
    ]),
    avoidNull(select) {
      return select?.title ?? "";
    },
    cleanFilters() {
      this.urlParams = {
        ...this.urlParams,
        numberOrder: "",
        initialDate: "",
        finalDate: "",
      };
      this.selectNoteType = {
        title: "",
        value: "",
      };
      this.selectStatus = {
        title: "",
        value: "",
      };
    },
    async search() {
      this.buttonLoading = true;
      this.setIsLoading(true);
      const urlParams = {
        page: this.urlParams.page,
        perPage: this.urlParams.perPage,
        numberOrder: this.urlParams.numberOrder ?? "",
        initialDate: this.urlParams.initialDate,
        finalDate: this.urlParams.finalDate,
        noteType: this.selectNoteType?.value ?? "",
        status: this.selectStatus?.value ?? "",
      };
      this.setUrlParams(urlParams);
      await this.externalMessages();
      this.buttonLoading = false;
      this.setIsLoading(false);
    },
  },
};
</script>

<style scoped></style>
