export default {
  setCheckedExternalMessages(state, response) {
    state.checkedExternalMessages = response;
  },

  setExternalMessages(state, response) {
    state.externalMessages = response;
  },

  setPagination(state, response) {
    state.pagination.total = response.total;
    state.pagination.perPage = response.perPage;
    state.pagination.currentPage = response.currentPage;
    state.pagination.lastPage = response.lastPage;
    state.pagination.nextPage = response.nextPage;
  },

  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.numberOrder = urlParams.numberOrder;
    state.urlParams.initialDate = urlParams.initialDate;
    state.urlParams.finalDate = urlParams.finalDate;
    state.urlParams.noteType = urlParams.noteType;
    state.urlParams.status = urlParams.status;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
};
