export default {
  checkedExternalMessages: [],
  isLoading: false,
  externalMessages: [],
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    numberOrder: "",
    initialDate: "",
    finalDate: "",
    noteType: "",
    status: "",
  },
};
