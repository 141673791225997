export default {
  setListingUsers(state, response) {
    state.listingUsers = response;
  },

  setUsersPagination(state, response) {
    state.usersPagination.total = response.total;
    state.usersPagination.perPage = response.perPage;
    state.usersPagination.currentPage = response.currentPage;
    state.usersPagination.lastPage = response.lastPage;
    state.usersPagination.nextPage = response.nextPage;
  },

  setUsersStatus(state, response) {
    state.isActive = response;
  },

  // setUsersEnabled(state) {
  //   state.listingUsers.isActive = true;
  // },

  // setUsersDisabled(state) {
  //   state.listingUsers.isActive = false;
  // },

  setUrlParams(state, urlParams) {
    state.urlParams.page = urlParams.page;
    state.urlParams.search = urlParams.search;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },
};
