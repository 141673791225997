<template>
  <v-card>
    <v-card-title>
      <v-row class="my-2 font-weight-medium">
        <v-col cols="12">
          <span class="text-center font-weight-medium"
            >Relatório de Mensagens</span
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            label="Id do Integrador"
            v-model="message.integratorId"
            clearable
            hide-details
            outlined
            dense
            flat
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="N Pedido Winthor"
            v-model="message.numeroPedidoWinthor"
            :disabled="numeroPedidoWinthorDisabled"
            clearable
            hide-details
            outlined
            dense
            flat
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="N Pedido Web"
            v-model="message.numeroPedidoLoja"
            :disabled="numeroPedidoLojaDisabled"
            clearable
            hide-details
            outlined
            dense
            flat
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            label="Tipo de Mensagem"
            v-model="message.tipoMensagem"
            :items="selectableMessageTypes"
            item-text="label"
            item-value="value"
            clearable
            hide-details
            outlined
            dense
            flat
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            label="Motivo da Falha"
            v-model="message.mensagemFalha"
            :items="selectableMessageFailures"
            item-text="label"
            item-value="value"
            clearable
            hide-details
            outlined
            dense
            flat
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="start">
        <v-col class="mt-0 pt-0"><span>Data de Envio</span></v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="mt-0 pt-0">
          <default-date-picker
            label="Data Inicial"
            :picker-date.sync="message.periodoInicial"
          />
        </v-col>
        <v-col cols="6" class="mt-0 pt-0">
          <default-date-picker
            label="Data Final"
            :picker-date.sync="message.periodoFinal"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-col cols="4">
        <default-button :loading="buttonDownloadReport" @click="downloadReport">
          <span>Baixar relatório</span>
        </default-button>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
import DefaultButton from "../defaultComponents/DefaultButton.vue";
import DefaultDatePicker from "../defaultComponents/DefaultDatePicker.vue";
import { mapActions } from "vuex";

export default {
  name: "ReportMessagesDialog",
  components: { DefaultButton, DefaultDatePicker },
  data: () => ({
    message: {
      periodoFinal: "",
      periodoInicial: "",
      integratorId: "",
      mensagemFalha: "",
      numeroPedidoLoja: "",
      numeroPedidoWinthor: "",
      tipoMensagem: "",
      // page: 1,
      // perPage: 10,
    },
    buttonDownloadReport: false,
    selectableMessageFailures: [
      { label: "Falha na requisição para G4", value: "1" },
      { label: "Falha de caractere especial", value: "2" },
    ],
    selectableMessageTypes: [
      { label: "Pedido Realizado", value: "CONFIRMACAO_PEDIDO" },
      { label: "Pagamento Reconhecido", value: "CONFIRMACAO_PAGAMENTO" },
      { label: "Chegada à Transportadora", value: "PEDIDO_DESPACHADO" },
      { label: "Pedido em Rota", value: "PEDIDO_EM_ROTA" },
      { label: "Pedido Entregue", value: "PEDIDO_ENTREGUE" },
      { label: "Anexar NF", value: "transfer-pending" },
      { label: "Repasse Programado", value: "transfer-scheduled" },
      { label: "NF Recusada", value: "transfer-disapproved" },
      { label: "Garantia", value: "message-guarantee" },
      { label: "Avaria/Reposição", value: "message-faulty" },
    ],
  }),
  methods: {
    ...mapActions("messages", ["downloadReportMessages"]),
    downloadReport() {
      this.buttonDownloadReport = true;
      this.downloadReportMessages(this.message);
      setTimeout(() => {
        this.buttonDownloadReport = false;
      }, 2000);
    },
  },
  computed: {
    numeroPedidoLojaDisabled() {
      return !!this.message.numeroPedidoWinthor;
    },
    numeroPedidoWinthorDisabled() {
      return !!this.message.numeroPedidoLoja;
    },
  },
};
</script>
<style></style>
